import React, { useMemo, useRef } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { Portal } from 'react-portal';
// import Close from '../Close';
import { useTransition, animated } from 'react-spring';
import { easePoly } from 'd3-ease';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { ILayerInfo, LayerService } from '@/api/services/layer.service';
import media from '@/ui/media';
import { linkLayerInfoProperties } from './linkProperties';
import { useMetadata } from '@/api/hooks/useMetadata';
import StatusIcon from '../icons/Status';
import useResponsive from '@/hooks/useResponsive';
import { SmallArrowIcon } from '../icons/ArrowIcon';
import { useProjectStore } from '@/stores/projectStore';
import { useGlobalStore } from '@/stores/globalStore';
import { useMapStore } from '@/stores/mapStore';
import { useRoomStore } from '@/stores/roomStore';
import { ShowValid } from '@/shared/map/tooltip/MapTooltip';

type ModalProps = {
    isOpen: boolean
    data: ILayerInfo | undefined
    onClose: () => void
    close?: boolean
    maxWidth?: number
}

const getMapCoverImagePath = (view: any) => {
    if (!view) return ''

    const urlPath = LayerService.getLayerImage({
        layerId: Number(view.default_image.owner_id),
        imageName: view.default_image.image_id,
        extension: view.default_image.extension
    })

    return urlPath
}

const LayerModal: React.FC<ModalProps> = ({ isOpen, onClose, data, maxWidth, close = false }) => {
    const ref = useRef<any>(null)
    const { isDesktop } = useResponsive()

    // const links = useProjectStore(state => state.links)

    const modalTransitions = useTransition(isOpen, {
        from: { opacity: 0, scale: 0.9 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.9 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    const imageURL = getMapCoverImagePath(data)
    const { metadata } = useMetadata()

    const currentNode = useMemo(() => {
        if (data && metadata && metadata.layers) {
            return metadata.layers[data.type_uid]
        }
        return null
    }, [data, metadata])

    const props = linkLayerInfoProperties(currentNode?.plugin_data, data?.plugin_data)

    return (
        <Portal node={document && document.getElementById('modals')}>
                    {modalTransitions((modalStyles, modal) => modal ? (
                        <Content style={modalStyles} ref={ref} $maxWidth={maxWidth}>
                            <Close onClick={onClose}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.74609 5.00586L13.3174 13.5772" stroke="#2F2F2F" strokeLinecap="round" />
                                    <path d="M4.87695 13.4473L13.4483 4.87596" stroke="#2F2F2F" strokeLinecap="round" />
                                </svg>
                            </Close>

                            {data && (
                                <Body>
                                    <Section>
                                        {isDesktop && (
                                            <TitleRow>
                                                <Name>{data.name}</Name>
                                            </TitleRow>
                                        )}

                                        {!isDesktop && (
                                            <MobileTitleRow>
                                                <PrevArrow />

                                                <Name>{data.name}</Name>

                                                <NextArrow />
                                            </MobileTitleRow>
                                        )}

                                        <ShowValid valid={Boolean(props['description'])}>
                                            <Description>{props['description']}</Description>
                                        </ShowValid>

                                        <PropertyRow>
                                            <PropertyCol>
                                                <ShowValid valid={Boolean(props['home_sq'])}>
                                                    <Property>
                                                        <PropertyName>Площадь дома</PropertyName>
                                                        <PropertyValue>
                                                            <span>{props['home_sq']} м<sup>2</sup></span>
                                                        </PropertyValue>
                                                    </Property>
                                                </ShowValid>

                                                <ShowValid valid={Boolean(props['address'])}>
                                                    <Property>
                                                        <PropertyName>Адрес</PropertyName>
                                                        <PropertyValue>{props['address']}</PropertyValue>
                                                    </Property>
                                                </ShowValid>
                                            </PropertyCol>

                                            <PropertyCol>
                                                <ShowValid valid={Boolean(props['land_sq'])}>
                                                    <Property>
                                                        <PropertyName>Площадь участка</PropertyName>
                                                        <PropertyValue>{props['land_sq']} соток</PropertyValue>
                                                    </Property>
                                                </ShowValid>

                                                <ShowValid valid={Boolean(props['status'])}>
                                                    <Property>
                                                        <PropertyName style={{ opacity: 0 }}>Статус</PropertyName>
                                                        {props['status'] == 0 && (
                                                            <StatusRow>
                                                                <StatusIcon type="busy" />
                                                                <PropertyValue>Занят</PropertyValue>
                                                            </StatusRow>
                                                        )}
                                                        {props['status'] == 1 && (
                                                            <StatusRow>
                                                                <StatusIcon type="booked" />
                                                                <PropertyValue>Забронирован</PropertyValue>
                                                            </StatusRow>
                                                        )}
                                                        {props['status'] == 2 && (
                                                            <StatusRow>
                                                                <StatusIcon type="available" />
                                                                <PropertyValue>Свободен</PropertyValue>
                                                            </StatusRow>
                                                        )}
                                                    </Property>
                                                </ShowValid>
                                            </PropertyCol>
                                        </PropertyRow>

                                        <ShowValid valid={Boolean(props['url'])}>
                                            <PropertyRow>
                                                <LinkMore href={props['url']} target="_blank">Получить планировки</LinkMore>
                                            </PropertyRow>
                                        </ShowValid>

                                    </Section>
                                    <ImageSection>
                                        <Image src={imageURL} />
                                    </ImageSection>
                                </Body>
                            )}
                        </Content>
                    ) : '')}
            <ScrollDisabler />
        </Portal  >
    )
}

export default LayerModal

const PrevArrow = () => {
    const links = useProjectStore(state => state.links)
    const layer = useGlobalStore(state => state.layer)
    const setLayer = useGlobalStore(state => state.setLayer)
    const addHistory = useRoomStore(state => state.addHistory)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const setTooltip = useMapStore(state => state.setTooltip)

    const handleClick = (e) => {
        e.preventDefault()
        let prevIndex = 0

        const currentIndex = links.findIndex(link => link == layer)
        if (currentIndex === 0) {
            prevIndex = links.length - 1
        } else {
            prevIndex = currentIndex - 1
        }

        const prev = links[prevIndex]
    
        addHistory(prev)
        setLayer(prev)
        setTooltip(null)
        setZoomSeat(Number(prev))
    }

    return (
        <ArrowPrevWrapper onClick={handleClick}>
            <SmallArrowIcon />
        </ArrowPrevWrapper>
    )
}

const NextArrow = () => {
    const links = useProjectStore(state => state.links)
    const layer = useGlobalStore(state => state.layer)
    const setLayer = useGlobalStore(state => state.setLayer)
    const addHistory = useRoomStore(state => state.addHistory)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const setTooltip = useMapStore(state => state.setTooltip)

    const handleClick = (e) => {
        e.preventDefault()
        let nextIndex = 0

        const currentIndex = links.findIndex(link => link == layer)
        if (currentIndex === links.length - 1) {
            nextIndex = 0
        } else {
            nextIndex = currentIndex + 1
        }

        const next = links[nextIndex]
    
        addHistory(next)
        setLayer(next)
        setTooltip(null)
        setZoomSeat(Number(next))
    }

    return (
        <ArrowNextWrapper onClick={handleClick}>
            <SmallArrowIcon />
        </ArrowNextWrapper>
    )
}


const Description = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #97968F;

    margin-bottom: 12px;
`


const Row = styled.div`
    display: flex;
`
const PropertyCol = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    width: 50%;
    flex-basis: 50%;
    flex-shrink: 0;

    ${media.sm`
        width: auto;
        flex-basis: unset;
        flex-shrink: 0;
    `}
`
const PropertyRow = styled(Row)`
    ${media.sm`
        justify-content: space-between;
    `}
`

const Arrow = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`
const ArrowPrevWrapper = styled(Arrow)`
    margin-left: -10px;
`
const ArrowNextWrapper = styled(Arrow)`
    transform: rotate(180deg);
    margin-right: -10px;
`

const Col = styled.div`
    width: 50%;
    flex-basis: 50%;
    flex-shrink: 0;
`

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    ${media.lg`
        justify-content: center;
    `}
`
const MobileTitleRow = styled(TitleRow)`
    justify-content: space-between;
`


const Body = styled(Row)`
    flex-wrap: wrap;
`


const Property = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`

const PropertyValue = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    padding-right: 8px;
    margin-top: 4px;
    
    span {
        position: relative;
    }

    sup {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        top: -0.2em;
    }
`

const StatusRow = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;

    ${PropertyValue} {
        margin-left: 8px;
    }
`

const PropertyName = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #83B617;
`

const Close = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 1px solid #FFFFFF; */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: linear-gradient(164.21deg, #F8F8F7 12.84%, #A8A89D 91.47%);

    ${media.lg`
        top: 10px;
        right: 10px;
    `}
`

const LinkMore = styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-decoration-line: underline;
    color: #949494;

`

const Image = styled.img`
    max-height: 265px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
`

const Name = styled.h2`
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    font-size: 35px;
    line-height: 46px;
    color: #000000;

    /* ${media.sm`
        font-size: 24px;
        line-height: 36px;
    `} */
`


const ImageSection = styled(Col)`
    padding: 15px 15px 15px 0;
    display: flex;
    align-items: center;

    ${media.lg`
        display: none;
    `}
`
const Section = styled(Col)`
    padding: 50px 60px;

    ${media.lg`
        width: 100%;
        flex-basis: 100%;
    `}

    ${media.phone`
        padding: 40px 20px;
    `}
`

const ScrollDisabler = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`

const Content = styled(animated.div) <{ $maxWidth?: number }>`
    max-width: 991px;
    width: 100%;
    position: absolute;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 20001;

    bottom: 30px;
    right: 30px;
 

    ${({ $maxWidth }) => $maxWidth && css`
        max-width: ${$maxWidth}px;
    `}


    * > {
        font-family: 'IBM Plex Sans', sans-serif;
    }

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.96) 50%, rgba(255, 255, 255, 0.4) 455.81%);
    border-radius: 10px;

    ${media.lg`
        max-width: 100%;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `}
`

const Backdrop = styled(animated.div)`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;

  display: flex;
  justify-content: center;
  align-items: center;
`
